import KatalLogger from "@amzn/katal-logger";

const DEFAULT_MAX_LOG_LINE_SIZE = 100000  
/*
	This method calls publishes the info level logs to cloudwatch using katalLogger
	Restricitng the message size to 10000 chars as after that katal logger gets stuck and can only publish the logs if the message is
	only little above 10000 chars, if not the entire message gets stuck in pending.
*/
export const katalLoggerInfo = (path: string, info: string, data: any) => {
	const katalLogger = katalLoggerUtil(path)
    var stringifiedData = JSON.stringify(data)
	var truncatedData = stringifiedData.length > DEFAULT_MAX_LOG_LINE_SIZE ? stringifiedData.substring(0, DEFAULT_MAX_LOG_LINE_SIZE) : stringifiedData
	katalLogger.info(`${info}: ${truncatedData}`)
}

/*
	This method calls publishes the Error level logs to cloudwatch using katalLogger
	Restricitng the message size to 10000 chars as after that katal logger gets stuck and can only publish the logs if the message is
	only little above 10000 chars, if not the entire message gets stuck in pending.
*/
export const katalLoggerError = (path: string, info: string, data: any) => {
    const katalLogger = katalLoggerUtil(path)
	var stringifiedData: string = JSON.stringify(data)
	var truncatedData = stringifiedData.length > DEFAULT_MAX_LOG_LINE_SIZE ? stringifiedData.substring(0, DEFAULT_MAX_LOG_LINE_SIZE) : stringifiedData
	katalLogger.error(`${info}: ${truncatedData}`)
}


export const katalLoggerUtil = (path: string) => new KatalLogger({
    url: "https://3rlu31dfol.execute-api.us-west-2.amazonaws.com/prod/v1/log",
    maxLogLineSize: 100000,
    logToConsole: true,
    context: {
        path: path
    }
});