import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import { getRegionRealm } from './utils/utils';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
    let region = getRegionRealm()
    return new KatalMetricsDriverSushi.Builder()
        .withDomainRealm('prod', `${region}`)
        .withErrorHandler(metricsConsoleErrorHandler)
        .build();
};

const makePublisher = (): KatalMetrics.Publisher => {

    const stage = "Beta";
    const metricsDriver = makeMetricsDriver();
    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite(`EagleEyeUI-${stage}`)
        .withServiceName('EagleEyeUI')
        .build();
    return new KatalMetrics.Publisher(
        metricsDriver,
        metricsConsoleErrorHandler,
        initialMetricsContext);
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
